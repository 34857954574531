import PropTypes from 'prop-types'
import React from 'react'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo backdrop-blur">
      <img src="../../images/profile.jpg" style={{"maxWidth": "100%",
        "maxHeight": "100%",
        "display": "block",
        "borderRadius": "50%"
      }}></img>
    </div>
    <div className="content backdrop-blur">
      <div className="inner backdrop-blur">
        <h1>Dr. SAAI RAM THEJAS</h1>
        <p>
        <div><br/>Hello! I'm an Otorhinolaryngologist with an obsession for cricket, fondness towards food, and a passion for driving.</div>
        </p>
      </div>
    </div>
    <nav className="backdrop-blur">
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            About Me
          </button>
        </li>
        <li>
          <button>
            <a href="https://drive.google.com/file/d/18Y9291PpJMh8EDzxPVHFeJafkrg4ktyN/view?usp=sharing" target="_blank" style={{textDecoration: 'none'}}>
              Resume
            </a>
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('call')
            }}
          >
            Call For Appointments
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
