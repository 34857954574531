import PropTypes from 'prop-types'
import React from 'react'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About Me</h2>
            <div><br/>I am a consultant ENT surgeon based out of Hyderabad, India. Although I am originally from Chennai, I grew up in Hyderabad and I am a total Hyderabadi at heart.</div>
            <div><br/>I did all my schooling from Gitanjali Devshala, Secunderabad and my undergraduation from Bhaskar Medical College, Moinabad.
            Whilst in college I was head of the student committee which organized sport and cultural events. This put me in good stead for managing individuals along with giving 
            me organizational skills. In school, I was part of the student council and took part in all literary and sport activities. </div>
            <div><br/>I pursued my Masters in Otorhinolaryngology in Meenakshi Medical College, Kanchipuram where apart from academics I took part in organizing international workshops and conferences.</div>
            <div><br/>I also have a Masters degree in Hospital Administration and management from the National Institute of Business Management, Trivandrum.</div>
            <div><br/>My passions include driving and teaching, which is why I am presently working at a Medical College and Hospital in Hyderabad. 
            I'm also interested in research and I have publications in both National and International journals.</div>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="mailto:thejas@entorl.com" method="GET" target="_blank">
            <div className="field">
              <label htmlFor="subject">Subject</label>
              <input type="text" name="subject" id="subject" />
            </div>
            <div className="field">
              <label htmlFor="Body">Message</label>
              <textarea name="body" id="Body" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a
                href={"https://www.google.com/maps/place/MediPoint+Polyclinic/@17.4691392,78.486786,15z/data=!4m5!3m4!1s0x0:0xd0921c9b8e8c103b!8m2!3d17.4691392!4d78.486786"}
                className="icon fa fa-map-marker"
                target="_blank"
              >
                <span className="label">Maps</span>
              </a>
            </li>
            <li>
              <a 
                href={"https://www.researchgate.net/profile/Saai_Ram_Thejas2"} 
                className="icon fa fa-university" 
                target="_blank"
              >
                <span className="label">Research</span>
              </a>
            </li>
            <li>
              <a 
                href={"https://scholar.google.com/citations?user=pWOS9oQAAAAJ&hl=en"}
                className="icon fa fa-graduation-cap"
                target="_blank" 
              >
                <span className="label">Google Scholar</span>
              </a>
            </li>
            <li>
              <a
                href={"https://www.semanticscholar.org/author/Saai-Ram-Thejas/88005643"}
                className="icon fa fa-book"
                target="_blank" 
              >
                <span className="label">Semantic Scholar</span>
              </a>
            </li>
            <li>
              <a
                href={"https://www.instagram.com/21thejas/"}
                className="icon fa fa-instagram"
                target="_blank" 
              >
                <span className="label">Instagram</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
        <article
          id="call"
          className={`${this.props.article === 'call' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Call for appointments</h2>
            <div style={{marginBottom: 20}}>
                <span className="topic">Mobile - </span> <a className="number" href={"tel:+918897995656"}><span>+918897995656</span> </a> <br/> 
                <span className="topic">Landline - </span> <a className="number" href={"tel:04027750555"}><span>04027750555</span> </a>
            </div>
            
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
